@import "_theme-variables.scss";

#dashboard {
    .card-header {
        border-bottom: 1px;
        //border-bottom-style: dashed;
        padding: 0 0 8px;
    }

    #dashboard-wrapper>.card-body {
        // padding: 20px 0 0;
    }

    @media only screen and (max-width: 969px) {
        #dashboard-wrapper>.card-body {
            padding: 0px 0 0;
        }
    }

    h3 {
        font-weight: 700;
    }

    #filter {
        font-weight: 600;

        .form-control {
            margin: 0;
            border-bottom: none;
            border-radius: 999px;
            padding: 10px 15px;

            &:focus {
                background: $white;
            }
        }
    }
}


.qr-img {
    width: -webkit-fill-available;
}

.custom-h2 {
    font-size: 2rem;
    /* Adjust the font size */
    font-weight: bold;
    /* Adjust the font weight */
    margin-top: 1.5rem;
    /* Adjust the top margin */
    margin-bottom: 1.5rem;
    /* Adjust the bottom margin */
    line-height: 1.3;
    /* Adjust the line height */
    color: #333;
    /* Adjust the text color */
}


.custom-h3 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.2;
}

.chart-donut {
    height: 200px;
}

@media only screen and (max-width: 768px) {
    .custom-h3 {
        font-size: 1.2rem;
        margin-top: 1rem;
    }

    .custom-height {
        height: 90% !important;
    }

    .apexcharts-datalabel-value {
        font-size: 13px;
    }

}