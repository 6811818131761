@import "_theme-variables.scss";

#package {
    .card {
        padding: 25px;
    }

    .deposit-wrapper {
        padding: 40px
    }

    .deposit-copy {
        padding: 10px 25px;
        background: #c99401;
        border-radius: 999px;
        margin-top: 20px;
        color: white;
    }

    .fa-copy {
        cursor: pointer;
    }

    .package-wrapper {
        background: $white;
        text-align: center;
        padding: 0;
        border-radius: 10px;

        .card-header {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            background: $black;
            color: white;
        }

        .card-body {
            padding: 15px;
        }
    }
}

.usd {
    font-size: 12px;
    font-weight: 700;
}

.token {
    position: absolute;
    top: 32px;
    //right: 15px;
    right: 3px;
}

.investedName {
    font-size: 14px;
}

.harvest_wrapper {
    p {
        margin-bottom: 10px;
    }
}

.token-underline {
    a:hover {
        text-decoration: underline !important;
        color: black !important
    }
}


#team-tabs {
    .nav-pills {
        margin-bottom: 15px;
        gap: 10px;

        .nav-link {
            border-radius: 999px;
            background: $black_soft;
            color: $white;


            &.active {
                background: $purple;
                color: $white;
            }
        }
    }

    .mobile-nav-items>li {
        margin-right: 10px;
    }

}

#card_wrapper {
    .card-body {
        background: $white;
        padding: 15px 20px;
        border-radius: 10px
    }

    .form-control {
        margin: 0;
        border-bottom: none;
        border-radius: 999px;
        padding: 10px 15px;
        background: $light_grey;
    }
}

#team-table {

    th,
    td {
        background: $white;
    }
}

@media only screen and (max-width: 968px) {
    .margin-right-10{
        margin-right: 10px;
    }
    
    .package-header{
       display: flex;
        flex: 0 0 auto;
        text-align: center;
    }
    .package-content{
        display: flex;
        justify-content: space-between;
    }

    .package-content-header{
        display: none;
    }
    .package-button{
        width: 100% !important;
        margin-top: 20px;
    }
    .hide{
        display: none;
    }

    .custom-card-body{
        font-size: 11px;
        padding: 0px !important;
    }

    .gap-left-right{
        position: relative;
        right: 5px;
     }
     .top-gap{
        margin-top: .8rem !important;
     }

}

@media only screen and (min-width: 968px) {
    .web-hide{
        display: none;
    }
    .package-header{
        // display: flex;
         //flex: 0 0 auto;
         text-align: center;
     }
     .gap-left-right{
        padding-left: 5px;
        padding-right: 5px;
        flex: .2;
     }

}

.stake-modal-balance-box{
    display: flex;
    flex: 2;
    justify-content: end;
    gap:5px
}

.stake-modal-balance0-title-box{
    flex: 1;
}

.grey-border {
    border: 1px solid grey; /* Set the border color to grey */
    border-radius: 5px; /* Optional: Add rounded corners */
    padding: 10px; /* Optional: Add padding for better appearance */
    padding-top: 15px;
}

.amount-box{
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin:0;
}