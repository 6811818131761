@import "_theme-variables.scss";

#team {
    .summary_wrapper {
        .card-header {
            border-bottom: 1px;
            //border-bottom-style: dashed;
            // padding: 0 0 8px;
            padding-left: 0;
            padding-right: 0;
        }

        .card-body {
            padding: 1rem 0 0 0;
        }

        h3 {
            font-weight: 700;
        }
    }

    .summaryToken_wrapper_col {
        align-items: center;
    }

    @media only screen and (max-width: 768px) {

        #summaryToken_wrapper,
        .summary_wrapper {
            .card {
                height: 100%;
            }
        }

        .summaryToken_wrapper_col {
            align-items: normal;
            margin-bottom: 8px;

            svg {
                margin-top: 5px;
            }
        }
    }

    #team-tabs {
        .nav-pills {
            margin-bottom: 15px;
            gap: 10px;
            display: none; //temporarily hide the tabs

            .nav-link {
                border-radius: 999px;
                background: $black_soft;
                color: $white;


                &.active {
                    background: $purple;
                    color: $white;
                }
            }
        }

        .mobile-nav-items>li {
            margin-right: 10px;
        }

    }

    .select {
        margin: 0;
        border-bottom: none;
        border-radius: 999px;
        padding: 10px 15px;
        background-color: $white;
    }

    #card_wrapper {
        .card-body {
            background: $white;
            padding: 15px 20px;
            border-radius: 10px
        }

        .form-control {
            margin: 0;
            border-bottom: none;
            border-radius: 999px;
            padding: 10px 15px;
            background: $light_grey;
        }
    }

    #team-table {

        th,
        td {
            background: $white;
        }
    }
}

#hierarchy-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 15px;

    .hierachy-icon {
        .fa {
            cursor: pointer
        }
    }
}

.grey-bd {
    padding: 12px 20px;
    background: $white;
    border-radius: 999px;
    width: 100%;
}

.token-balance {
    grid-gap: 3rem !important;
    gap: 3rem !important;
}

@media only screen and (max-width: 768px) {
    .token-balance {
        margin-top: 8px;
        flex-wrap: wrap;
        grid-gap: 1rem !important;
        gap: 1rem !important;
    }
}

.scrollable-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    display: -webkit-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.custom-front-summary {
    font-size: 1.1rem;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    .summary-data-align {
        text-align: left;
    }

    .title-padding-bot {
        padding-bottom: 29px;
    }
}


@media only screen and (min-width: 768px) {

    .summary-data-align {
        text-align: right;
    }
}