#profile {
    .profile-card {
        margin-bottom: 25px;

        &:last-child {
            margin: 0
        }

        h6 {
            margin-bottom: 8px;
        }

        p {
            margin-bottom: 0
        }
    }
}

.verification-send {
    position: absolute;
    top: 15px;
    right: 20px;
    border-left: 1px solid #495057;
    padding-left: 20px;
    cursor: pointer;
}

.verification-send-wallet {
    position: absolute;
    top: 165px;
    right: 35px;
    border-left: 1px solid #495057;
    padding-left: 20px;
    cursor: pointer;
}