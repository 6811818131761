@import "_theme-variables.scss";

.auth-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background-color: $purple;
}

.auth-wrapper {
    max-width: 435px;
    width: 100%;
    box-sizing: border-box;

    .card {
        background-color: $white;

        .card-body {
            padding: 10px;

            .card-title {
                // margin-bottom: 30px;
                text-align: center;
            }
        }
    }
}

.auth-logo {
    width: 240px;
    margin-bottom: 25px;
    height: auto;
}

.login-footer {
    padding-top: 30px;
    padding-bottom: 10px;

    span {
        display: flex;
        justify-content: center;
        margin-top: 15px;

        a {
            cursor: pointer;
            margin-left: 5px;
            color: $purple;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.reset-password {
    cursor: pointer;
    width: fit-content;
    float: right;
    margin-bottom: 30px;

    &:hover {
        text-decoration: underline;
    }
}

.check-phone {
    position: absolute;
    top: 18px;
    right: 0;
}

.form-false {
    color: $red;
}

.form-true {
    color: $green;
}

#password-show {
    position: absolute;
    top: 25px;
    right: 15px;
    cursor: pointer;
}

.dropdown-width {
    width: 75px;
}

.dropdown-toggle::after {
    font-family: FontAwesome;
    content: '\f078';
    position: absolute;
    top: 5px;
    right: 12px;
    border: none;
    font-size: 12px;
}