@import "_theme-variables.scss";

#wallet {
    #withdraw-title {
        h6 {
            margin-bottom: 5px
        }

        h5 {
            margin: 0;
        }
    }

    #filter {
        font-weight: 600;

        .form-control {
            margin: 0;
            border-bottom: none;
            border-radius: 999px;
            padding: 10px 15px;

            &:focus {
                background: $white;
            }
        }
    }

    #collapse p {
        margin: 0
    }

    .card-footer {
        background: transparent;
        padding-bottom: 0;
    }

    .btn.no-bg:active {
        background: transparent;
    }

    .token-wrapper {
        align-items: center;
        margin-bottom: 15px;

        img {
            margin-right: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .table {
        a:hover {
            color: $black;
            text-decoration: underline !important;
        }
    }

    .withdraw-address-input {
        border-bottom: none;
        padding: 0.375rem 0.75rem;
        margin: 0;

        &:focus {
            background: $white;
        }
    }

    .select {
        margin: 0;
        border-bottom: none;
        border-radius: 999px;
        padding: 10px 15px;
        background-color: $white;
    }
}

@media only screen and (min-width: 968px) {
    .mw-100 {
        min-width: 100px;
    }


    .mw-110 {
        min-width: 110px;
    }

    .button-col {
        padding-left: 0px;
    }
}

.time-sec {
    background: red;
    color: white;
    padding: 10px;
    border-radius: 8px;
    font-size: 16px;
}