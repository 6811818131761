@import "_theme-variables.scss";

/*general*/
.badge {
    padding: 5px 10px;
    font-weight: 600;

    &.main {
        background-color: $light_yellow !important;
        color: $black;
    }

    &.bg-ongoing {
        color: $purple;
        background-color: $light_yellow;

    }

    &.bg-ending {
        color: #f05a24;
        background-color: #ffdfd7;
    }
}

.row {
    margin-right: calc(-.3 * var(--bs-gutter-x));
    margin-left: calc(-.3 * var(--bs-gutter-x));
}

.row>* {
    padding-right: calc(var(--bs-gutter-x) * .3);
    padding-left: calc(var(--bs-gutter-x) * .3);
}

.bg-main {
    background-color: $light_yellow;
}

.bg-multiple {
    background: $multiple-gradient;
}

.btn {
    border-radius: 50px;
    width: 100%;
    padding: 8px 18px;
    font-size: 14px;
    font-weight: 600;

    &.outline,
    &.outline:active {
        border: 1px solid $black;
    }

    &.no-bg {
        background: transparent;
        border: none
    }
}

.btn-secondary {
    &:disabled {
        opacity: 0.35
    }
}

.btn-sm {
    padding: 6px 25px;
}

.text-gradient {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    background: linear-gradient(to bottom, $red, $color_more);
}

.de-flex {
    display: flex;
    justify-content: space-between;
}

.de-flex>.de-flex-col {
    display: flex;
    justify-content: center;
    align-items: center;
}


hr {
    display: block;
    clear: both;
    border-top: solid 1px #ddd;
    margin: 40px 0 40px 0;
}



.btn-main {
    display: block;
    width: max-content;
    text-align: center;
    color: $black_more;
    background: $green;
    border-radius: 5px;
    letter-spacing: normal;
    outline: 0;
    font-weight: bold;
    text-decoration: none;
    padding: 8px 40px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 15px 0px rgba(42, 124, 110, 0);
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0px 0px 10px 0px $green;
        transition: all 0.3s ease;
    }

    &.inline {
        display: inline-block;
        margin-right: 15px;

        &.white {
            color: $color_more !important;
            background: $white;
        }
    }
}

.m-a-0 {
    margin: auto 0;
}

.text-right {
    text-align: right;
}

.cursor-pointer {
    cursor: pointer;
}

.copy-position {
    position: absolute;
    top: 8px;
    right: 15px;
    cursor: pointer;
}

.col-space {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    gap: 0px;
}

// .col {
//   flex-direction: column;
//   display: flex;
//   justify-content: space-between;
// }

#orcGraph p,
#orcGraph h5 {
    margin: 0;
}

.div-center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.border-left {
    border-left: 1px solid;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.box {
    padding: 12px;
    border: 1px solid;
    border-radius: 15px;

    &:hover {
        background: #212447;
    }
}

.box-br30 {
    padding: 5px 20px;
    border: 1px solid;
    border-radius: 30px;
}

#earnDate p {
    padding: 2px 10px;
    border-radius: 15px;
    font-size: 14px;
    border: 1px solid;
    margin: 0;
    // color: $white;
}

.active-date {
    background: $yellow_more;
    border: 1px solid $yellow_more !important;
    color: $black;
}

.max-fit {
    max-width: fit-content;
}

#stake h4 {
    font-weight: normal;
    margin: 0;
}

#staking p {
    margin: 0;
    align-self: end;
}

//modal
.modal-header {
    border: none;
}

.modal-title {
    margin: 0 !important
}

.swal2-popup {
    background: $dark_blue !important;
    color: $general !important;
}

.dropdown-menu.show {
    width: 100% !important;
}

#select {
    button {
        background-color: $light_yellow;
        border: none;
        color: $black_more;
        text-align: left;
    }

    .dropdown-menu {
        width: 100%;
        margin: 0;

    }


    .dropdown-item {
        margin-bottom: 4px;
    }

    img {
        width: 10%;
        margin-right: 10px;
    }
}

//form
.form-control {
    font-size: 14px;
    border: none;
    border-bottom: 1px solid $black;
    border-radius: 0;
    padding: 15px 12px;
    margin-bottom: 1.3rem;

    &:focus,
    &:disabled {
        box-shadow: none;
        background: transparent;
        border-bottom: 1px solid $black;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: $light_grey;
    }
}

.form-floating>label {
    color: $black !important
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $light_grey inset;
}

.form-control::placeholder {}

.form-select,
.form-select:focus {
    border: none;
    padding: 15px 12px;
    font-size: 14px;
    box-shadow: none;
    border-bottom: 1px solid;
    border-radius: 0;
}

.form-label {
    margin-bottom: 0.3rem;
}

.form-sub {
    top: 8px;
    right: 15px;
    // color: $black;
}

.form-text {
    color: $yellow_more;
}

.text-purple {
    color: $purple
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.rangeslider-horizontal {
    height: 10px;
}

.rangeslider-horizontal .rangeslider__handle {
    width: 25px;
    height: 25px;
}

.rangeslider-horizontal .rangeslider__handle:after {
    content: none;
}

.nft-height {
    height: 440px;
    overflow-x: auto;
}

#dashboardLink {
    a {
        color: $white;
        text-decoration: none;

        .blue-bg-gradient:hover {
            background: rgba(47, 70, 152, 0.7);
        }
    }

    h3,
    h5,
    span {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

#walletConnect {
    justify-content: space-between;

    &:hover {
        font-weight: bold;
        background: $green-soft;
        transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
        color: black;
    }
}

.convertRate {
    color: $white;
    font-size: 14px;
}

#scroll-to-top div {
    background: $green;
}

.hierarchy-bg {
    border-radius: 15px;
    padding: 5px 10px;
    border: 1px solid $gray;
    font-size: 14px;
}

.ml-20 {
    margin-left: 20px;
    color: $yellow_more;
}

.cursor-default {
    cursor: default;
}

.width-a {
    width: auto;
}

.iJhIuy {
    font-size: 20px !important;
}

.disconnect-dd {
    left: 15px;
    transform: none;
}

#h-value {
    color: $white;
    text-decoration: none;

    &:hover {
        color: $green;
    }
}

#hierarchy {
    .fa-fw {
        font-size: 8px;
        margin: 0 3px;
    }
}

// iframe {
//   pointer-events: none;
// }

.font-normal {
    font-weight: normal;
}

.w-a {
    width: 20px !important;
}

.jumbo-bg {
    overflow: hidden;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
}

.text-left {
    text-align: left;
}

.justify-content-initial {
    justify-content: initial;
}

#presales-box {
    color: $white;

    p {
        margin-bottom: 0.5rem;
    }

    h4 {
        color: $yellow_more;
    }
}

.vr {
    min-height: 100%;
    background-color: $white;
    opacity: 0.5;
}

.wallet-m3 {
    margin: 0 40px;
}

#tablet-main-logo {
    display: none;
}

.presales-main1 {
    font-family: goodTimes;
    font-weight: normal;
    line-height: 80px;
}

.presales-main2 {
    font-family: zArista;
    font-size: 100px;
    font-weight: bold;
    letter-spacing: 5px;
    text-shadow: 1px 1px 5px #fad93b, 1px 1px 5px #ccc;

    .glow {
        color: #fad93b;
    }
}

.text-glow {
    text-shadow: 0px 0px 5px #fad93b, 0px 0px 5px #ccc;
}

.word-break {
    word-break: break-all;
}

#timer {
    padding: 10px 20px;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;

    h4 {
        margin-bottom: 0;
        color: #daf6ff;
        text-shadow: 0 0 20px #0aafe6, 0 0 20px rgb(10 175 230 / 0%);

        span {
            font-size: 18px;
        }
    }
}

.participation-text {
    margin: 0;
    line-height: 2rem;
}

#sales-text {
    span {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 8px;
        color: $yellow_more;
    }
}

.banner-height {
    // height:250px;
    border-radius: 25px;
}

.margin-left {
    margin-left: 0.5rem !important;
}

.t-logo {
    margin-bottom: 6px;
}

.t-logo2 {
    margin-bottom: 8px;
}

@media only screen and (max-width: 376px) {
    .icon-width {
        font-size: 20px !important;
        width: 22.7% !important;
    }
}

.icon-width {
    font-size: 20px;
    width: 20.7%;
}

.certik-mp {
    margin-top: 15% !important;
}

.w-certik-80 {
    width: 80% !important;
}

.fa-lg {
    font-size: 25px;
}

/*** card design ***/
.widget-visitor-card {
    overflow: hidden;
    padding: 10px 0;

    // border: none;
    i {
        color: #fff;
        font-size: 80px;
        position: absolute;
        bottom: 30px;
        opacity: 0.3;
        right: -10px;
        transform: rotate(-15deg);
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        i {
            transform: rotate(0deg) scale(1.4);
            opacity: 0.5;
        }
    }
}

.social-widget-card {
    &:hover i {
        opacity: 1;
        transform: scale(1.1);
    }

    i {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 48px;
        opacity: 0.4;
        transition: all ease-in 0.3s;
    }
}

.progress-bar-striped {
    background-color: $red;
}

/*** Swal ***/
.swal2-styled.swal2-confirm {
    background-color: $green !important;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgb(42 124 110 / 50%) !important;
}

.fa-circle {
    font-size: 10px;
}

.dropdownIni {
    display: none;
}

.show {
    display: block;
}

.horizontal-scroll {
    overflow: auto;
    white-space: nowrap;
}

@media (min-width: 1200px) {
    .display-4 {
        font-size: 2rem;
        font-weight: bold;
    }
}

.hyperlink-removed {
    color: black !important;
    cursor: pointer !important;
    text-decoration: none !important;
}

.order-card {
    color: #fff;
    overflow: hidden;

    // border: none;
    .card-icon {
        position: absolute;
        right: -17px;
        font-size: 100px;
        top: 20px;
        opacity: 0.5;
    }
}

.border-bg {
    text-align: center;
    padding: 16px;
    border-radius: 999px;
    border: 1px solid #cccccc;
}

.border-page-title {
    overflow: hidden;
    text-align: center;
    margin-bottom: 0;
    text-transform: uppercase;
}

.border-page-title:before {
    right: 1.5em;
    margin-left: -50%;
}

.border-page-title:after {
    left: 1.5em;
    margin-right: -50%;
}

.border-page-title:after,
.border-page-title:before {
    background-color: $green;
    content: '';
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.subnav {
    //background: $purple;
    background: #D0D0D0;

    .nav-link {
        padding: 10px 5px;
    }

    p {
        font-size: 12px;
    }
}

.pd-card {
    padding: 30px 0;
}

.swal2-title {
    color: $white !important;
    padding: 8px 1em 0 !important;
}

.swal2-html-container {
    color: $white !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    margin: 3px 1.6em 0.3em !important;
}

.swal2-popup {
    padding: 0 0 1.7em !important;
}

.swal2-icon.swal2-warning {
    border-color: #fec022 !important;
    color: #fec022 !important;
}

.chartContainer {
    height: 400px;
}

.text-token {
    color: #bbb;
}

#token tr {
    &:hover {
        background: #1f325c;
    }
}

.chartview {
    height: 100%;
    width: 100%;
}

.chart-container {
    flex: 1;
}

.br-15 {
    border-radius: 15px;
}

.mobileLogo {
    display: none;
}

.headerRow {
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

.search-btn {
    border-radius: 0 !important;
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    margin: 0;
}

.address-bd {
    padding: 8px 15px;
    border-radius: 999px;
    font-size: 14px;
    background: $white;
    color: $yellow_more;
}

#withdraw-earn {
    .card-body {
        padding: 0;

        .rowData {
            padding: 14px 23px;
            border-bottom: 1px solid $blue;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
            }

            p {
                margin: 0;
            }

            &:nth-child(2) {
                background-color: $blue;
            }
        }
    }
}

#withdraw-bal {
    .card {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

#withdrawal {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    p {
        margin: 0;
    }

    .withdrawal-amt {
        border-radius: 999px;
        color: white;
        padding: 5px 15px;
    }
}

.scrollable-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    display: -webkit-box;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

#announcement {
    h4 {
        font-weight: 800;
    }

    .date {
        color: #979797;
        margin: 0;
    }

    .content {
        height: 60px;
        line-height: 1.5;
        word-break: break-word;
        margin: 12px 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* number of lines to show */
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

#trading {
    height: 380px;
    overflow: hidden;

    p {
        margin-bottom: 8px;
    }

    .autoscroll {
        width: 100%;
        height: auto;
        top: 0;
        // animation: scroll 50s linear infinite; /* Adjust the animation duration as needed */

        @keyframes scroll {
            0% {
                transform: translateY(0);
            }

            100% {
                transform: translateY(-100%);
            }
        }

        &:hover {
            -webkit-animation-play-state: paused;
            animation-play-state: paused;
        }
    }
}

.market {
    background: '#ebf0f6';
    border-radius: 23px;
}

.qr-bg {
    position: absolute;
    width: 45%;
    // top: 59.5%;
    // left: 50%;
    // transform: translate(-50%, -50%);
}

.mt-35 {
    margin-top: 35px !important;
}

.p-25 {
    padding: 25px;
}

#image-icon {
    border-radius: 999px;
    background-color: #f8f8f8;
}

.border-main {
    border: 2px solid $blue;
}

.contract-desc {
    background-color: $white;
    padding: 15px;
    border-radius: 10px;
}

.contract-title {
    width: fit-content;
    border-bottom: 1px solid $card-outline;
    margin: auto auto 12px;
    line-height: 25px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: $purple
}

.buttonload {
    border: none;
    color: white;
    padding: 12px 24px;
    font-size: 16px;
}

.fa-spin {
    margin-right: 8px;
}

.loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: 15px auto;
    margin-bottom: 20px;
    position: relative;
    color: $purple;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;
}

@keyframes animloader {
    0% {
        box-shadow: -38px -12px, -14px 0, 14px 0, 38px 0;
    }

    33% {
        box-shadow: -38px 0px, -14px -12px, 14px 0, 38px 0;
    }

    66% {
        box-shadow: -38px 0px, -14px 0, 14px -12px, 38px 0;
    }

    100% {
        box-shadow: -38px 0, -14px 0, 14px 0, 38px -12px;
    }
}

.slick-list {
    // border-radius: 15px;
}

.modal {
    --bs-modal-header-padding: 2rem 2rem 0;
    --bs-modal-padding: 2rem;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #8749ea;
    --bs-btn-border-color: #8749ea;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #8749ea;
    --bs-btn-hover-border-color: #8749ea;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #8749ea;
    --bs-btn-active-border-color: #8749ea;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #8749ea;
    --bs-btn-disabled-border-color: #8749ea;
}

.btn-close:focus {
    box-shadow: none;
}

.slick-slide img {
    // height: 212px;
    // max-height: 400px;
    // aspect-ratio: 13/4;
}

@media only screen and (max-width: 576px) {
    .slick-slide img {
        // aspect-ratio: 5/3;
    }
}

.wrapper {
    z-index: 5;
    background: linear-gradient(to bottom, $purple 10%, rgba($purple, 0.0) 50%);
}

.bg-purple {
    background-color: $purple;
    // transition:all 1s ease;
}

#authentication {
    .language-btn {
        .btn-primary {
            --bs-btn-color: #000000;
            --bs-btn-bg: #fff;
            --bs-btn-border-color: #fff;
            --bs-btn-hover-color: #000000;
            --bs-btn-hover-bg: #fff;
            --bs-btn-hover-border-color: #fff;
            --bs-btn-focus-shadow-rgb: 49, 132, 253;
            --bs-btn-active-color: #000000;
            --bs-btn-active-bg: #fff;
            --bs-btn-active-border-color: #fff;
            --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --bs-btn-disabled-color: #000;
            --bs-btn-disabled-bg: #fff;
            --bs-btn-disabled-border-color: #fff;
        }
    }
}