/*font*/
@font-face {
    font-family: 'zArista';
    src: url('../font/z-arista.regular.ttf');
}

@font-face {
    font-family: 'goodTimes';
    src: url('../font/FontsFree-Net-good-times-rg.ttf');
}

/* Montserrat - Thin */
@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

/* Montserrat - ExtraLight */
@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

/* Montserrat - Light */
@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

/* Montserrat - Regular (Normal) */
@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Montserrat - Medium */
@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

/* Montserrat - SemiBold */
@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

/* Montserrat - Bold */
@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* Montserrat - ExtraBold */
@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

/* Montserrat - Black */
@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}