@import "_theme-variables.scss";

.tradingview-widget-copyright {
    display: none;
}

#arbitrage_wrapper {
    height: 600px;
    overflow: auto;
}

.token-badge {
    padding: 6px 10px;
    max-width: 96px;
    min-width: 56px;
    background-color: #d2d1cf !important;
    color: $black;
}